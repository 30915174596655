import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import "./MainChat.css";
interface ChatListProps {
  conversations:
    | { id: string; name: string; lastMessage: string }[]
    | undefined;
  onSelectConversation: (id: string) => void;
}

const ChatList: React.FC<ChatListProps> = ({
  conversations = [], // Default to empty array if undefined
  onSelectConversation,
}) => {
  return (
    <div className="chat-list text-align-center">
      <h1 className="text-align-center">Chats</h1>
      <ListGroup>
        {conversations.length > 0 ? (
          conversations.map((convo) => (
            <ListGroupItem
              key={convo.id}
              action
              onClick={() => onSelectConversation(convo.id)}
            >
              <strong>{convo.name}</strong>
              <p>{convo.lastMessage}</p>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem>No conversations available</ListGroupItem>
        )}
      </ListGroup>
    </div>
  );
};

export default ChatList;
