import { Button, Card, Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCommunityDetailsQuery } from "../../store/slices/communitySlice";
import "./CommunityDetail.css"; // Make sure to create this CSS file

export interface SingleMember {
  data: {
    _id: string;
    name: string;
    gender: string;
    email: string;
    bio: string;
    birth_year: string;
    birth_month: string;
    birth_day: string;
    images: string[];
    native_language: string;
    language_to_learn: string;
    createdAt: string;
    imageUrls: string[];
    __v: number;
  };
}

const CommunityDetail = () => {
  const { id: communityId } = useParams();
  const { data, isLoading, error } = useGetCommunityDetailsQuery(communityId);
  const navigate = useNavigate();

  if (!communityId) {
    return <div className="error-message">Invalid community ID</div>;
  }

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner animation="border" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        An error occurred. Please try again later.
      </div>
    );
  }

  // Ensure data is defined
  const member = data as SingleMember;
  const memberDetails = member?.data;

  if (!memberDetails) {
    return <div className="error-message">Member details not found</div>;
  }

  return (
    <Container fluid className="community-detail-container">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Img
              variant="top"
              src={memberDetails.imageUrls[0] || "placeholder.jpg"}
              alt={memberDetails.name}
              className="community-image"
            />
            <Card.Body>
              <Card.Title className="text-center">
                {memberDetails.name}
              </Card.Title>
              <Card.Text className="text-center">
                <strong>Bio:</strong> {memberDetails.bio}
              </Card.Text>
              <Card.Text className="text-center">
                <strong>Native Language:</strong>{" "}
                {memberDetails.native_language}
              </Card.Text>
              <Card.Text className="text-center">
                <strong>Learning:</strong> {memberDetails.language_to_learn}
              </Card.Text>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="primary"
                  size="lg"
                  className="mx-2"
                  onClick={() => alert("Followed")}
                >
                  Follow
                </Button>
                <Button
                  variant="secondary"
                  size="lg"
                  className="mx-2"
                  onClick={() => alert("Unfollowed")}
                >
                  Unfollow
                </Button>
                <Button
                  variant="success"
                  size="lg"
                  className="mx-2"
                  onClick={() => navigate(`/chat/${memberDetails._id}`)}
                >
                  Chat
                </Button>
                <Button
                  variant="warning"
                  size="lg"
                  className="mx-2"
                  onClick={() => alert("Calling")}
                >
                  Call
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CommunityDetail;
