import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

interface ChatWindowProps {
  messages: { text: string; sender: string; timestamp: string }[];
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages }) => {
  return (
    <Card className="chat-window">
      <Card.Body>
        <ListGroup>
          {messages.map((message, index) => (
            <ListGroupItem
              key={index}
              className={message.sender === "me" ? "text-end" : ""}
            >
              <strong>{message.sender}:</strong> {message.text}
              <small className="text-muted d-block">{message.timestamp}</small>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default ChatWindow;
