import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";
import MessageInput from "./MessageInput";
import "./MainChat.css";
const mockConversations = [
  { id: "1", name: "John Doe", lastMessage: "Hey, how are you?" },
  { id: "2", name: "Jane Smith", lastMessage: "See you tomorrow!" },
  { id: "3", name: "Jane Smith", lastMessage: "See you tomorrow!" },
];

const mockMessages = [
  { text: "Hey, how are you?", sender: "John Doe", timestamp: "10:30 AM" },
  {
    text: "I’m good, thanks! How about you?",
    sender: "Me",
    timestamp: "10:31 AM",
  },
];

const Chat: React.FC = () => {
  const [selectedConversation, setSelectedConversation] = useState<
    string | null
  >(null);

  const handleSelectConversation = (id: string) => {
    setSelectedConversation(id);
  };

  const handleSendMessage = (message: string) => {
    console.log("Message sent:", message);
  };

  return (
    <Container fluid>
      <Row>
        <Col md={4} className="chat-list-container">
          <ChatList
            conversations={mockConversations}
            onSelectConversation={handleSelectConversation}
          />
        </Col>
        <Col md={8} className="chat-window-container">
          {selectedConversation ? (
            <>
              <ChatWindow messages={mockMessages} />
              <MessageInput onSendMessage={handleSendMessage} />
            </>
          ) : (
            <p>Select a conversation to start chatting</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Chat;
