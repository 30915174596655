import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

interface MessageInputProps {
  onSendMessage: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <Form className="message-input">
      <Form.Control
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <Button onClick={handleSend} variant="primary" className="mt-2">
        Send
      </Button>
    </Form>
  );
};

export default MessageInput;
