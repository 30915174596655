import React from "react";

const CoursesMain = () => {
  return (
    <div>
      <h1>Courses</h1>
    </div>
  );
};
export default CoursesMain;
